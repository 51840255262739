import React from 'react';
import styled from '@emotion/styled';
import PropTypes, { InferProps } from 'prop-types';
import { Breadcrumbs } from './Breadcrumb';
import { Grid, GridCell } from './Grid';
import breakpoints from './Theme/breakpoints';
import InnerHtmlStyling from './InnerHtmlStyling';
import Headline1 from './Typography/Heading/Headline1';
import Body1 from './Typography/Body/Body1';

interface ContentProperties {
  withTableStyles?: boolean;
  children: React.ReactNode;
}

export const BreadcrumbsWrapper = styled.div`
  display: flex;
  justify-content: start;
  flex-basis: 100%;
  padding-bottom: 2rem;
`;

const Header = styled(Headline1)`
  text-align: center;
  margin-bottom: 1.5rem;
`;

const PageWrapper = styled.div`
  margin: 0 auto 32px;
  max-width: 970px;
  box-sizing: border-box;
  width: 100%;
  padding: 0 16px;

  .contact-page {
    text-align: center;
    padding: 0;
  }

  .contact-page .description {
    margin: 0;
    padding: 0 32px 24px;
  }

  .contact-page .countries-container {
    margin: 16px auto;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
  }

  .contact-page .country {
    flex-basis: 182px;
    padding: 0 0 20px;
  }

  .contact-page .country .fu-heading-bold {
    font-size: 15px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 5px;
  }

  .contact-page .country .fu-flag-text {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .contact-page .fu-image-container {
    font-size: 15px;
    display: block;
    width: 78px;
    height: 78px;
    margin: auto;
    border: 1px solid #979797;
    border-radius: 50px;
    background-repeat: no-repeat;
    background-position: 45%;
    background-size: cover;
  }

  .contact-page .editorial-container {
    margin: 0 0 32px;
  }

  .contact-page .editorial-container p {
    font-size: 1rem;
    margin: 0;
    padding: 0;
    line-height: 1.6rem;
    letter-spacing: 0;
  }

  .contact-page .contact-container {
    margin: 16px auto;
    padding: 0 32px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: space-between;
    letter-spacing: 0;
  }

  .contact-page h3 {
    font-size: 1rem;
    line-height: 2rem;
    margin: 0;
    padding: 0;
    text-align: left;
  }

  .contact-page .contact {
    flex-basis: 25%;
    flex-grow: 0;
    flex-shrink: 0;
    min-width: 12.5rem;
    padding: 0 0 24px;
    margin: 0;
    text-align: left;
  }

  .contact-page .contact p {
    font-size: 13px;
    font-weight: bold;
    padding: 0 12px 8px 0;
    margin: 0;
    letter-spacing: 0;
  }

  .contact-page .image-flag-ar {
    background-image: url("https://fashionunited.info/global-assets/fu20164/dist/images/flags/svg/ar.svg");
  }

  .contact-page .image-flag-au {
    background-image: url("https://fashionunited.info/global-assets/fu20164/dist/images/flags/svg/au.svg");
  }

  .contact-page .image-flag-be {
    background-image: url("https://fashionunited.info/global-assets/fu20164/dist/images/flags/svg/be.svg");
  }

  .contact-page .image-flag-ca {
    background-image: url("https://fashionunited.info/global-assets/fu20164/dist/images/flags/svg/ca.svg");
  }

  .contact-page .image-flag-cn {
    background-image: url("https://fashionunited.info/global-assets/fu20164/dist/images/flags/svg/cn.svg");
  }

  .contact-page .image-flag-cl {
    background-image: url("https://fashionunited.info/global-assets/fu20164/dist/images/flags/svg/cl.svg");
  }

  .contact-page .image-flag-co {
    background-image: url("https://fashionunited.info/global-assets/fu20164/dist/images/flags/svg/co.svg");
  }

  .contact-page .image-flag-fr {
    background-image: url("https://fashionunited.info/global-assets/fu20164/dist/images/flags/svg/fr.svg");
  }

  .contact-page .image-flag-de {
    background-image: url("https://fashionunited.info/global-assets/fu20164/dist/images/flags/svg/de.svg");
  }

  .contact-page .image-flag-in {
    background-image: url("https://fashionunited.info/global-assets/fu20164/dist/images/flags/svg/in.svg");
  }

  .contact-page .image-flag-it {
    background-image: url("https://fashionunited.info/global-assets/fu20164/dist/images/flags/svg/it.svg");
  }

  .contact-page .image-flag-mx {
    background-image: url("https://fashionunited.info/global-assets/fu20164/dist/images/flags/svg/mx.svg");
  }

  .contact-page .image-flag-nl {
    background-image: url("https://fashionunited.info/global-assets/fu20164/dist/images/flags/svg/nl.svg");
  }

  .contact-page .image-flag-nz {
    background-image: url("https://fashionunited.info/global-assets/fu20164/dist/images/flags/svg/nz.svg");
  }

  .contact-page .image-flag-pe {
    background-image: url("https://fashionunited.info/global-assets/fu20164/dist/images/flags/svg/pe.svg");
  }

  .contact-page .image-flag-es {
    background-image: url("https://fashionunited.info/global-assets/fu20164/dist/images/flags/svg/es.svg");
  }

  .contact-page .image-flag-ch {
    background-image: url("https://fashionunited.info/global-assets/fu20164/dist/images/flags/svg/ch.svg");
  }

  .contact-page .image-flag-gb {
    background-image: url("https://fashionunited.info/global-assets/fu20164/dist/images/flags/svg/gb.svg");
  }

  .contact-page .image-flag-us {
    background-image: url("https://fashionunited.info/global-assets/fu20164/dist/images/flags/svg/us.svg");
  }

  .contact-page .image-flag-ru {
    background-image: url("https://fashionunited.info/global-assets/fu20164/dist/images/flags/svg/ru.svg");
  }

  .overflow-scroll table {
    width: 100%;
  }

  .overflow-scroll table tr {
    text-align: center;
  }

  .overflow-scroll {
    overflow-x: scroll;
  }

  @media (min-width: ${breakpoints.lg}px) {
    .overflow-scroll {
      overflow-x: initial;
    }
  }

  @media (min-width: ${breakpoints.lg}px) {
    margin: 0 auto 48px;
    padding: 0;
  }
`;

const Content = styled(InnerHtmlStyling, {
  shouldForwardProp: (property) => property !== 'withTableStyles',
})<ContentProperties>`
  font-family: var(--font-primary);

  .text-gray-300 {
    font-size: 0.72rem;
  }
`;

const SubTitle = styled(Body1)`
  color: var(--primary-color);
  font-weight: 600;
  text-align: center;
  margin-bottom: .5rem;
`;

const staticPagePropertyTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
  ).isRequired,
  content: PropTypes.node.isRequired,
  subTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  withTableStyles: PropTypes.bool,
  disclaimer: PropTypes.node,
};

type StaticPageProperties = InferProps<
  typeof staticPagePropertyTypes
> & {
  withTableStyles?: boolean;
};

const StaticPage = ({
  breadcrumbs,
  content,
  subTitle,
  title,
  withTableStyles,
  disclaimer,
} : StaticPageProperties) => (
  <Grid>
    <GridCell padding="0">
      <PageWrapper>
        <BreadcrumbsWrapper>
          <Breadcrumbs crumbs={breadcrumbs} />
        </BreadcrumbsWrapper>
        {subTitle && (
          <SubTitle>{subTitle}</SubTitle>
        )}
        <Header tag="h1">{title}</Header>
        <Content withTableStyles={withTableStyles}>
          {content}
        </Content>
        <Content>
          {disclaimer}
        </Content>
      </PageWrapper>
    </GridCell>
  </Grid>
);

StaticPage.propTypes = staticPagePropertyTypes;

export default StaticPage;
